export const ASIDE_TYPE_CLOSE = "close"
export const ASIDE_PARAM_TYPE = "aside"
export const ASIDE_PARAM_ID = "asideId"
export const ASIDE_PARAM_VIEW = "asideView"
export const ASIDE_PARAM_OPTIONS = "asideOptions"

export type AsideOption = "hideAndKeepMounted"
export type AsideOptions = Set<AsideOption>

export type AsideType =
  | "task"
  | "document"
  | "preview"
  | "email"
  | typeof ASIDE_TYPE_CLOSE

const _arrayOfAllTypes = <T extends AsideType[]>(
  array: T & ([AsideType] extends [T[number]] ? unknown : "Invalid"),
) => array

export const AsideTypes = _arrayOfAllTypes([
  "task",
  "document",
  "preview",
  "email",
  ASIDE_TYPE_CLOSE,
])
