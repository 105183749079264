import type { Route } from "next"

/**
 * function that takes a pathname and replaces the slugs ([locale]) with the given attribute values of a passed object ({ locale: "de" "})
 *
 * @param pathname the pathname with slugs to replace
 * @param attributes the object with the attribute values to replace the slugs with
 * @param search the search string to append to the pathname
 * @returns
 */
export function replaceSlugs(
  pathname: Route,
  attributes?: { [key: string]: string | string[] | undefined },
  search?: string,
): Route {
  const newPathname = pathname.replaceAll(/\[(.*?)\]/g, (_, slug): string => {
    return (
      (Array.isArray(attributes?.[slug])
        ? (attributes?.[slug]?.at(0) ?? "")
        : (attributes?.[slug] as string)) ?? ""
    )
  }) as Route

  if (search) {
    return `${newPathname}?${search}`
  }

  return newPathname
}

export function getSlugNameByValue(
  params: {
    [key: string]: string | string[] | undefined
  },
  slugValue: string,
): string | null {
  const [name] =
    Object.entries(params).find(([, value]) =>
      Array.isArray(value) ? value.includes(slugValue) : value === slugValue,
    ) ?? []

  if (name) return name
  return null
}
