import type { Route } from "next"
import { type NextRequest, NextResponse } from "next/server"
import { replaceSlugs } from "./navigation"

export const ACTIVATE_SESSION_COOKIE = {
  AUTH_CODE: "auth_code",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
} as const

export const PUBLIC_ROUTE = {
  ACCESS: "/access/[token]" satisfies Route<"/access/[token]">,
  CLAIMANT_POA:
    "/[locale]/claimant/poa/[token]" satisfies Route<"/[locale]/claimant/poa/[token]">,
  SIGNIN: "/[locale]/auth/signin" satisfies Route<"/[locale]/auth/signin">,
  FORGOT_PW:
    "/[locale]/auth/forgot-password" satisfies Route<"/[locale]/auth/forgot-password">,
  REGISTER:
    "/[locale]/auth/registration" satisfies Route<"/[locale]/auth/registration">,
  ACTIVATE_SESSION:
    "/[locale]/auth/activate-session" satisfies Route<"/[locale]/auth/activate-session">,
  STATIC: "/[locale]/static" satisfies Route<"/[locale]/static">,
  IMPRINT:
    "/[locale]/static/imprint" satisfies Route<"/[locale]/static/imprint">,
  TOS: "/[locale]/static/privacy-statement" satisfies Route<"/[locale]/static/privacy-statement">,
  FORM: "/[locale]/form" satisfies Route<"/[locale]/form">,
} as const

export const COMMON_USER_ROUTE = {
  RESET_PW:
    "/[locale]/auth/reset-password" satisfies Route<"/[locale]/auth/reset-passord">,
  SET_PW:
    "/[locale]/auth/set-password" satisfies Route<"/[locale]/auth/set-password">,
  ONBOARDING: "/[locale]/onboarding" satisfies Route<"/[locale]/onbdoarding">,
  IN: "/[locale]/in" satisfies Route<"/[locale]/in">,
} as const

export const LAWFIRM_ONLY_ROUTE = {
  IN: "/[locale]/lawyer" satisfies Route<"/[locale]/lawyer">,
} as const

export const SERVICE_PROVIDER_ONLY_ROUTE = {
  IN: "/[locale]/account" satisfies Route<"/[locale]/account">,
} as const

export const SYSTEM_ADMIN_ONLY_ROUTE = {
  IN: "/system" satisfies Route<"/system">,
  CREATE_ORGANIZATION:
    "/system/organization/create" satisfies Route<"/system/organization/create">,
  ORGANIZATION_PAGE:
    "/system/organization/[organizationId]" satisfies Route<"/system/organization/[organizationId]">,
} as const

export function isRouteAllowedForServiceProvider(
  pathname: string,
  locale: string,
) {
  return (
    isPublicRoute(pathname, locale) ||
    Object.values(COMMON_USER_ROUTE).some((path) => {
      return pathname?.startsWith(replaceSlugs(path, { locale }))
    }) ||
    Object.values(SERVICE_PROVIDER_ONLY_ROUTE).some((path) => {
      return pathname?.startsWith(replaceSlugs(path, { locale }))
    })
  )
}
export function isUnavailableAsideView(request: NextRequest) {
  const searchParams = request?.nextUrl ?? {}
  if (searchParams) {
    const aside = searchParams.searchParams.get("aside")
    const asideView = searchParams.searchParams.get("asideView")
    if (aside === "task" && asideView !== "overview") {
      return true
    }
  }
  return false
}
export function isRouteAllowedForLawfirm(pathname: string, locale: string) {
  return (
    isPublicRoute(pathname, locale) ||
    Object.values(COMMON_USER_ROUTE).some((path) => {
      return pathname?.startsWith(replaceSlugs(path, { locale }))
    }) ||
    Object.values(LAWFIRM_ONLY_ROUTE).some((path) => {
      return pathname?.startsWith(replaceSlugs(path, { locale }))
    })
  )
}

export function isSystemAdminOnlyRoute(pathname: string, locale: string) {
  return Object.values(SYSTEM_ADMIN_ONLY_ROUTE).some((path) => {
    return pathname?.startsWith(replaceSlugs(path, { locale }))
  })
}

export function isPublicRoute(pathname: string, locale: string) {
  return Object.values(PUBLIC_ROUTE).some((path) => {
    return pathname?.startsWith(replaceSlugs(path, { locale }))
  })
}

export function isFallbackToAppStartPage(pathname: string) {
  return ["/", "", "/in/de"].includes(pathname)
}

export function redirect(url: URL): NextResponse {
  return NextResponse.redirect(url)
}

export const isSigninRoute = (pathname: string) => {
  return pathname?.includes("/auth/signin")
}
