"use client"
import TextField, {
  type FilledTextFieldProps,
  type OutlinedTextFieldProps,
  type StandardTextFieldProps,
  type TextFieldProps,
} from "@mui/material/TextField"
import {
  type ChangeEvent,
  useState,
  type FC,
  useCallback,
  useEffect,
  useRef,
} from "react"

export type {
  TextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  FilledTextFieldProps,
}

const isNotNil = (value: unknown) => value !== null && value !== undefined

const EnhancedTextField: FC<TextFieldProps> = (props) => {
  const inputRef = useRef<string>(
    (props.value as string) ?? (props.defaultValue as string) ?? "",
  )
  const [isLabelShrunk, setIsLabelShrunk] = useState<boolean>(
    isNotNil(props.defaultValue) ||
      isNotNil(props.value) ||
      isNotNil(props.InputProps?.startAdornment),
  )

  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      inputRef.current = e.target.value
      const newIsLabelShrunk =
        isNotNil(inputRef.current) || isNotNil(props.InputProps?.startAdornment)
      if (newIsLabelShrunk !== isLabelShrunk) {
        setIsLabelShrunk(newIsLabelShrunk)
      }
      return props.onChange?.(e)
    },
    [props?.onChange, props.InputProps?.startAdornment, isLabelShrunk],
  )

  useEffect(() => {
    const newIsLabelShrunk =
      isNotNil(props.value) ||
      isNotNil(props.defaultValue) ||
      isNotNil(props.InputProps?.startAdornment) ||
      isNotNil(inputRef.current)
    if (newIsLabelShrunk !== isLabelShrunk) {
      setIsLabelShrunk(newIsLabelShrunk)
    }
  }, [
    isLabelShrunk,
    props.value,
    props.defaultValue,
    props.InputProps?.startAdornment,
  ])

  return (
    <TextField
      onFocus={(e) => e.target.select()}
      InputLabelProps={{ shrink: isLabelShrunk }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Tab") {
          // @ts-expect-error - blur is not in the type
          e.target.blur()
        }
      }}
      {...props}
      onChange={onChangeProxy}
    />
  )
}

export { EnhancedTextField as TextField }

// TODO: accessible number input, see:
// https://mui.com/material-ui/react-text-field/#type-quot-number-quot
// export const TextFieldNumber: FC<MuiTextFieldProps> = (props) => (
//   <MuiTextField
//     {...props}
//     InputProps={{
//       inputMode: 'numeric',
//       pattern: '[0-9]*',
//     }}
//   />
// )
