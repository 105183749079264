"use client"

import React, { type ReactNode } from "react"

import { useTheme } from "ui/styles"
import { Box } from "ui/layout/Box"
import { Typography } from "ui/data-display/Typography"

export interface AuthPaperHeaderProps {
  title: ReactNode
  subTitle: ReactNode
}
export default function AuthPaperHeader({
  title = "",
  subTitle = "",
}: AuthPaperHeaderProps) {
  const theme = useTheme()
  return (
    <Box className="text-center">
      <Typography color={theme.palette.text.primary} variant="h6">
        {title}
      </Typography>
      <Typography color={theme.palette.text.disabled} variant="body1">
        {subTitle}
      </Typography>
    </Box>
  )
}
